/* Import Theme Styles */
/* COLORS */
/* Complementary and Supporting Brand Colors */
/*
NOTE: Extend brand color names to use in Website Colors
so that they are easy to change globally and not tied to a color name.
*/
/* Website Colors */
/*
Use these variables when setting colors in theme.
Inherit values from above if necessary.
*/
/* FONTS */
/* MIXINS & HELPERS */
.color-primary {
  color: #00b4c9; }

.color-secondary {
  color: #81dfb0; }

.color-bright {
  color: #ffae00; }

.clearfix:after, .bg-default:after {
  content: "";
  display: table;
  clear: both; }

.q-hidden {
  display: none; }

.font-display {
  font-family: "Open Sans", sans-serif; }

.bg-default {
  background-color: #eeeeee; }

.main-color {
  color: #00b4c9; }
.main-color a {
  color: #00b4c9; }

.text-light {
  color: white; }
.text-light a {
  color: #ffae00; }
.text-light a:hover {
  color: #ff9839; }
.text-light .btn {
  background: #ffae00;
  color: white; }
.text-light .btn:hover {
  background: #ff9839;
  color: white; }

.center-text {
  text-align: center; }

/********* @group Global Layout Styles **********/
* {
  box-sizing: border-box; }

body.main-body {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  color: #555555; }

p {
  color: #555555; }

.blue-big p {
  color: white; }

h1 {
  font-size: 68px;
  line-height: 1.2; }

h2 {
  color: #333333;
  font-size: 42px;
  line-height: 1.3; }

h3 {
  font-size: 26px;
  line-height: 1.2; }

h4 {
  font-size: 18px;
  line-height: 1.4; }

h5 {
  text-transform: uppercase;
  color: #01151F;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2; }

@media (max-width: 885px) {
  em {
    font-size: 1em !important; } }

.no-padding {
  padding: 0; }

a, a:visited, a:link, a:hover, a:active {
  text-decoration: none; }

main > .row:not(:first-child):not(:last-child) {
  padding-top: 5%;
  padding-bottom: 5%; }

main img {
  max-width: 100%; }

.more {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: lighter;
  color: #A9A9A9;
  padding-top: 6px; }

.quantum {
  font-size: 29px; }

.gray_column {
  background: #F3F4F5;
  margin-top: 40px;
  padding: 20px;
  border-radius: 8px;
  border: none; }

/* @end Global Layout Styles */
/********* @group Header **********/
.logo-navigation {
  padding: 0; }

.logo:hover {
  text-decoration: none !important; }

@media (max-width: 485px) {
  .logo {
    display: none; } }

@media (max-width: 485px) {
  .navbar-toggle {
    margin: 0; } }

.loginn p {
  padding: 0.9em;
  margin: 0; }

.logged-in-view {
  text-align: center;
  padding: 0.9em;
  display: flex; }

.media-object img {
  border-radius: 100%; }

.language_select {
  border: none;
  text-transform: uppercase;
  color: #00b4c9;
  background: transparent;
  margin-bottom: 10px; }

.language_select:focus {
  border: none;
  outline: none; }

.language_select:hover {
  cursor: pointer; }

.search-button-icon {
  color: #304546 !important;
  font-size: 20px;
  padding: 10px 15px;
  background-color: transparent;
  border: none; }

.search-button-icon:hover {
  opacity: 0.8; }

.searchbar {
  border-radius: 20px;
  padding: 5px;
  border: 2px solid #00b4c9;
  color: #00b4c9; }

.HomepageHeader-hgroup2 {
  position: absolute;
  top: 30%;
  width: 100%;
  height: 140px;
  text-align: center;
  text-shadow: 0 0 30px rgba(8, 58, 61, 0.2); }
@media (max-width: 885px) {
  .HomepageHeader-hgroup2 {
    top: 20%; } }
@media (max-width: 415px) {
  .HomepageHeader-hgroup2 {
    top: 10%; } }

.HomepageHeader-hgroup2 h1 {
  color: white;
  font-size: 70px;
  font-weight: lighter; }
@media (max-width: 885px) {
  .HomepageHeader-hgroup2 h1 {
    font-size: 30px; } }
@media (max-width: 415px) {
  .HomepageHeader-hgroup2 h1 {
    font-size: 22px; } }

.HomepageHeader-hgroup2 h2 {
  font-size: 24px;
  color: white; }
@media (max-width: 885px) {
  .HomepageHeader-hgroup2 h2 {
    font-size: 20px;
    margin-top: 0; } }
@media (max-width: 415px) {
  .HomepageHeader-hgroup2 h2 {
    display: none; } }

.HomepageHeader2 {
  position: relative; }

.sflanguagesDropDown {
  color: #00b4c9;
  text-transform: uppercase;
  border: none; }

.rsRow {
  height: 100px; }

.logged-test a {
  color: #00b4c9;
  margin-right: 5px; }

.header-right-group div {
  float: left; }
@media (max-width: 485px) {
  .header-right-group div {
    display: none; } }

.header-right-group .dropdown {
  margin-top: 20px; }

.header-right-group .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }
@media (max-width: 1290px) {
  .header-right-group .dropdown-menu {
    left: -220%; } }
@media (max-width: 990px) {
  .header-right-group .dropdown-menu {
    left: 0; } }

.login-view-menu {
  left: -170%; }
@media (max-width: 990px) {
  .login-view-menu {
    left: 0; } }

.language-view-menu {
  left: -100%;
  min-width: 150px !important; }
@media (max-width: 990px) {
  .language-view-menu {
    left: 0; } }

/* @end Header */
/********* @group Main Content **********/
/* @end Main Content */
/********* @group Footer **********/
footer {
  margin: auto; }

.footer_social {
  font-size: 1.5em;
  margin-bottom: 5%; }

.footer_social a {
  color: white;
  border-right: 1px solid #444; }

.footer_social a:hover {
  text-decoration: none;
  color: #ffae00; }

.footer_social a {
  padding-left: 9px;
  padding-right: 12px; }

.qsitemap {
  margin-top: -6px;
  padding-top: 50px;
  background: #01151F;
  font-size: 16px; }

.qsitemap_bottom {
  padding-top: 50px;
  background: #01151F;
  font-size: 16px; }

.quantum-footer-logo {
  margin-bottom: 30px; }

.nav-sitemap > li {
  border: 0; }

.nav-sitemap > li a {
  text-transform: uppercase;
  color: #777777;
  padding: 5px 10px !important; }

.q-sitemap-stacked a {
  text-transform: capitalize !important;
  color: white !important;
  font-weight: 500;
  padding: 5px 10px !important; }

.nav-stacked a:hover, .nav-stacked a:focus {
  background-color: transparent !important;
  color: #ffae00 !important; }

.foot_nav {
  padding-top: 2%;
  padding-bottom: 2%;
  list-style: none;
  display: inline-block;
  text-align: center; }

.copyright {
  text-align: center; }

@media (max-width: 415px) {
  .nav-stacked li {
    display: none; } }

/* @end Footer */
/*CHANGES TO SITEFINITY STYLES*/
.sfL100Blue {
  background-image: url("/ResourcePackages/Bootstrap/assets/icons/blue.png") !important; }

.sfL100Green {
  background-image: url("/ResourcePackages/Bootstrap/assets/icons/green.png") !important; }

.sf-FileField-input {
  border-radius: 4px;
  height: 34px;
  padding: 5px;
  width: 100%;
  margin-top: 5px; }

.subscribe_button {
  margin-top: 3%; }

.RadScheduler_Metro .rsHeader {
  background: #00b4c9; }

.sf-lang-selector a, .sf-lang-selector a.selected {
  text-transform: capitalize;
  background-color: inherit !important;
  color: #00b4c9; }

.sf-lang-selector a.selected {
  font-weight: bold; }

.sf-img-thmb img {
  height: 40px !important;
  width: 40px !important; }

.sf-lang-selector a {
  padding: 0; }

.sf-Comment-count {
  margin-left: 0;
  margin-bottom: 10px; }

.sf-list-item a {
  padding-bottom: 10px;
  padding-top: 10px;
  color: #00b4c9;
  font-size: 20px; }

.sf-Image-wrapper img {
  max-width: 100%; }

.sf-list-content {
  color: gray;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0 !important;
  border-bottom: 1px solid lightgray; }

.video-banner {
  height: auto; }

.viewMapLnk {
  color: #00B4C9;
  font-size: 16px;
  font-weight: bold; }

.viewMapLnk:hover {
  cursor: pointer; }

.form-group {
  position: relative; }

.too-much {
  background-color: white; }

input[type="search"] {
  border: 1px solid #ccc;
  width: 100% !important; }

.search-dropdown-menu {
  border: none;
  background: transparent;
  box-shadow: none; }

.pagination {
  margin: 2%; }

.btn-quantum {
  background-color: #ffae00;
  border-color: #ffae00;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 20px; }

.btn-quantum:hover {
  background-color: #ff9839;
  border-color: #ff9839; }

.carousel-control.right, .carousel-control.left {
  background-image: none !important;
  color: #00b4c9}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.custom-slider {
  margin: auto;
  padding: 2%;
  width: 90%; }

video {
  max-width: 100%; }

.form-response {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 28px; }

/*CHANGES TO SITEFINITY STYLES END*/
/********* @group Navigation **********/
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
  background-color: inherit !important; }

@media (max-width: 885px) {
  .navbar-nav {
    margin: 0px !important; } }

.blue-horizontal {
  background-color: transparent;
  border: 0;
  font-size: 16px;
  margin-top: 20px; }

.upper-nav {
  color: #304546 !important; }

.upper-nav:hover {
  opacity: 0.8; }

.navbar-collapse {
  padding: 0 !important; }

.dropdown-toggle {
  padding-left: 0 !important;
  margin-right: 20px !important;
  color: #777777;
  font-weight: 600; }

.dropdown-menu {
  min-width: 200px; }

/* @end Navigation */
/********* @group Mega Menu **********/
.mega-menu ul {
  list-style: none;
  padding: 0; }

.mega-menu li {
  padding: 0.9rem; }

.mega-menu a {
  font-size: 16px;
  color: black; }

.mega-menu a:hover {
  color: #00b4c9; }

/*.mega-menu {
    border-radius: 0;
    margin-top: 0px;
    width: 650px;
        @media (max-width: 1085px) {
        width: 370px;
        }
        @media (max-width: 775px) {
            width: 100%;
        }
}

.mega-menu li {
	display: inline-block;
}*/
/*.mega-menu li.mega-menu-column {
	width: 210px;
        @media (max-width: 1085px) {
            width: 115px;
        }
        @media (max-width: 775px) {
            width: 100%;
        }
}


.mega-menu .nav-header {
	margin-bottom: 10px;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid $color-gray;
}

.mega-menu img {
          padding-bottom: 10px;
          width: 70px;
          @media (max-width: 775px) {
            display: none;
            }
}

.mega-menu a{
    color: #777;
}

.mega-menu span{
    @media (max-width: 885px) {
        display: none;
    }
    @media (max-width: 775px) {
            display: none;
    }
}

.mega-menu-column ul, .mega-menu-column li {
    padding: 0;
}

.mega-menu-column span {
    text-transform: none;
}

.mega-menu-column:hover{
    background-color: azure;
    cursor: pointer;
}

.even-menu{
    width: 430px;
}*/
/* @end Mega Menu */
/********* @group Events **********/
.sfcalendarEventPreviewTitle {
  margin-top: 0px; }

.event_details {
  border: 1px solid #ccc;
  padding: 2%;
  background-color: white; }

.event_date_place {
  background-color: #00b4c9;
  padding: 1%; }

.event_date_place p {
  color: white; }

.event_date_place address {
  color: white; }

.k-header {
  background-color: inherit;
  border-bottom-width: 0px; }

.events_div ul {
  list-style: none; }

.events_div a {
  color: #00b4c9; }

.events_container {
  background-color: rgba(255, 255, 255, 0.7); }

/* @end Events */
/********* @group Slider **********/
.carousel-inner > .item > a > img, .carousel-inner > .item > img {
  margin: auto !important; }

.glyphicon-chevron-right, .glyphicon-chevron-left {
  color: #00b4c9;
  font-size: 1em; }

.glyphicon-chevron-right {
  right: 30% !important; }

.glyphicon-chevron-left {
  left: 30% !important; }

.glyphicon-chevron-right, .glyphicon-chevron-left {
  font-size: 3em;
  color: #00b4c9;
  position: absolute;
  top: 50%; }
@media (max-width: 885px) {
  .glyphicon-chevron-right, .glyphicon-chevron-left {
    font-size: 1em; } }

.feat_text {
  position: absolute;
  top: 20%;
  left: 50%;
  margin-right: 10%;
  padding: 2%; }

.feat_text_2 {
  position: absolute;
  top: 40%;
  left: 5%;
  margin-left: 5%;
  padding: 2%; }

.inverted, .splash-text {
  background: rgba(0, 0, 0, 0.4); }

.feat_text.inverted, .feat_text.inverted, .blue-big, .blue-big a, .blue-big a:visited, .blue-big a:link, .blue-big a:hover, .blue-big a:active {
  color: white; }

.blue-big strong {
  color: black; }

.blue-big a:hover {
  background-color: inherit; }

.feat_heading {
  text-transform: uppercase;
  font-size: 52px;
  color: #00b4c9; }
@media (max-width: 1085px) {
  .feat_heading {
    font-size: 40px; } }
@media (max-width: 885px) {
  .feat_heading {
    text-transform: uppercase;
    font-size: 21px; } }
@media (max-width: 415px) {
  .feat_heading {
    font-size: 14px; } }

.inverted .feat_heading {
  color: white;
  text-shadow: 0 5px 10px #00b4c9; }

.feat_content {
  font-size: 30px;
  color: grey; }
@media (max-width: 1085px) {
  .feat_content {
    font-size: 20px; } }
@media (max-width: 885px) {
  .feat_content {
    font-size: 12px; } }
@media (max-width: 415px) {
  .feat_content {
    display: none; } }

.inverted .feat_content {
  color: white;
  text-shadow: 0 5px 10px #00b4c9; }

@media (max-width: 885px) {
  .carousel-indicators {
    bottom: 0; } }

.carousel-indicators li {
  background-color: #00b4c9;
  border: 2px solid grey; }

.carousel-control {
  display: block;
  width: 5% !important; }

.carousel-control.right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.1) 100%); }

.carousel-control.left {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0.0001) 100%); }

.carousel-inner {
  width: 97%;
  margin: auto;
  position: relative; }

.active a {
  background-color: white !important;
  color: #00b4c9 !important; }

.buttons-position {
  position: absolute;
  bottom: 5%;
  left: 7%; }

.buttons-position span {
  color: #00b4c9; }

.pause-button, .play-button:hover {
  cursor: pointer; }

/* @end Slider */
/********* @group News **********/
.shop_news {
  text-align: center;
  padding-top: 3%;
  padding-bottom: 3%; }

.news_container {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap; }

.news_item, .news_item_recent {
  margin-top: 1%;
  margin-bottom: 25px;
  padding: 0;
  border: 1px solid #eeeeee;
  background: rgba(255, 255, 255, 0.8); }
@media (max-width: 775px) {
  .news_item, .news_item_recent {
    width: 100%; } }

.news_item_recent:hover {
  box-shadow: 0 4px 15px #eeeeee; }

.homepage_news {
  border-radius: 8px;
  min-height: 575px; }

.homepage_news img {
  border-radius: 8px 8px 0 0; }

.homepage_news_text {
  padding: 20px; }

.news_image {
  text-align: center;
  margin-top: 20px; }

.news_item a {
  color: #565656; }

.news_heading {
  color: #00b4c9; }

.news_author {
  padding-top: 2%;
  padding-bottom: 2%;
  text-transform: uppercase; }

.news_summary {
  background-color: #81dfb0;
  padding: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  color: white;
  font-style: italic;
  border-radius: 10px; }

.news_column {
  padding: 2%; }

.news_text h4 {
  font-weight: bold; }

.news_text h4 a, .news_item h4 a {
  color: #00b4c9; }

.news_text h4 a:hover {
  color: #ffae00; }

.news_text p {
  font-size: 15px; }

.news_text span {
  font-size: 13px; }

.news-home {
  padding: 5%; }

.news-list-page {
  margin-top: 40px; }

.download_list {
  margin-top: 40px;
  margin-bottom: 40px; }

.download_item {
  border: 1px solid #eee;
  border-radius: 8px;
  background: #F3F4F5;
  text-align: center;
  margin-bottom: 30px;
  padding: 4%;
  min-height: 630px; }

.download_item:hover {
  box-shadow: 0 4px 15px #eeeeee; }

.download_item h3 {
  margin: 30px;
  font-size: 20px;
  min-height: 90px; }

.download_item h3 a {
  color: black;
  font-weight: bold; }

.download_item_image {
  border: 1px solid #eee; }

.download_file_size {
  text-transform: uppercase;
  color: #ccc;
  margin-top: 20px;
  margin-bottom: 20px; }

.btn-q-download {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.featured_news, .featured_news_recent {
  margin: 1%;
  margin-bottom: 25px;
  padding: 1%;
  background: #81dfb0;
  border: 1px solid #eeeeee;
  color: white;
  position: relative; }
@media (max-width: 775px) {
  .featured_news, .featured_news_recent {
    width: 100%; } }

.featured_news_recent h4 a {
  color: white; }

.featured_news_recent:hover {
  box-shadow: 0 4px 15px #81dfb0; }

.featured_news_recent a, .featured_news_recent p {
  color: white; }

.featured_news_recent img {
  width: 100%; }

.featured_news_recent span {
  color: white; }

.news_inner {
  padding: 30px; }

.blog_post_list {
  margin-bottom: 80px; }

.blog_item {
  border-bottom: 1px solid #ccc;
  padding-bottom: 40px;
  margin-top: 40px; }

.blog_item h3 {
  font-size: 24px;
  font-weight: 700; }

.blog_item a {
  color: #00b4c9; }

.blog_item h3 a:hover {
  color: black;
  text-decoration: underline; }

.date-and-author {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px; }

.ribbon {
  right: 0;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  text-align: right; }

.ribbon span {
  font-size: 15px;
  font-weight: lighter;
  color: #81dfb0;
  text-transform: uppercase;
  text-align: center;
  padding: 7px 12px;
  line-height: 20px;
  display: block;
  background: white;
  position: absolute;
  top: 19px;
  right: 0; }

.categories-list, .sf-Tags {
  margin-bottom: 30px; }

.categories-list a, .sf-Tags a {
  color: #00b4c9;
  font-size: 14px;
  font-weight: 700; }

.categories-list a:hover, .sf-Tags a:hover {
  color: black;
  text-decoration: underline; }

.subscription {
  padding: 0; }

.subscription p {
  font-size: 14px; }

.subscription label {
  font-size: 14px; }

.e-mail-subscription input {
  width: 100% !important;
  background: white;
  border-radius: 4px;
  margin-bottom: 15px; }

.subscription-button {
  width: 100%;
  border: none;
  background: #002529;
  text-transform: capitalize; }

.blogs-container {
  border-top: 1px solid #ccc;
  padding-top: 30px;
  padding-bottom: 30px; }

.blog_item_detail {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px; }

.blog-social {
  -webkit-padding-start: 0 !important;
  margin-top: 20px; }

.blog-social li {
  list-style: none;
  display: grid; }

.blog-social li a {
  color: #ccc;
  font-size: 18px;
  padding: 5px 5px 5px 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 100%; }

.blog-social li a:hover {
  color: #00b4c9; }

.blog_summary {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
  padding-right: 0;
  font-size: 18px;
  font-weight: bold;
  font-style: italic; }

.blog_summary_quote {
  color: #00b4c9;
  padding-right: 15px;
  line-height: 1.3; }

.featured_news_recent {
  margin-bottom: 25px; }

/* @end News */
/********* @group Services Styles **********/
.design_column {
  padding: 2%;
  text-align: center; }

.design_column img {
  padding-top: 2%;
  padding-bottom: 2%;
  width: 60%; }

.splash {
  position: relative;
  width: 100%; }

.splash-text {
  position: absolute;
  width: 60%;
  top: 35%;
  margin-left: 20%;
  text-align: center;
  font-weight: bold; }
@media (max-width: 885px) {
  .splash-text {
    margin: 0;
    width: 100%; } }
@media (max-width: 425px) {
  .splash-text {
    bottom: 0; } }

.splash-text h3 {
  font-size: 70px;
  color: white; }
@media (max-width: 885px) {
  .splash-text h3 {
    font-size: 40px; } }
@media (max-width: 415px) {
  .splash-text h3 {
    font-size: 16px; } }

.splash-text p {
  font-size: 24px;
  color: white; }
@media (max-width: 1085px) {
  .splash-text p {
    font-size: 16px; } }
@media (max-width: 885px) {
  .splash-text p {
    display: none; } }

.about_us_card {
  padding: 2%;
  margin: 2%;
  width: 95%;
  height: 500px;
  border: 1px solid #00b4c9;
  background: white; }

.about_us_card p {
  font-size: 14px; }

.careers {
  padding: 3%; }

.marketing_card h2 {
  color: white !important; }

.marketing_card p {
  color: white !important; }

.project {
  padding: 0; }

.project-phase {
  margin-top: 40px; }

.project-phase img {
  opacity: 0.3; }

.project-phase h3 {
  margin-top: -10px !important;
  color: black !important;
  margin-bottom: 10px;
  padding-left: 10px;
  font-weight: bold; }

.project-phase h3:after {
  content: url("/assets/Arrow.png");
  margin-left: 30px; }

.project-phase-last h3:after {
  content: "" !important; }

.project-phase p {
  color: black;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 40px; }

/* @end Services */
/********* @group Showcase Styles **********/
.showcase {
  background: #F3F4F6;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: -5px; }

.showcase h2 {
  margin-bottom: 40px;
  margin-top: 0; }

.showcase_heading {
  text-align: center;
  padding-bottom: 3%; }

.showcase_image {
  padding: 0; }

.showcase_download a {
  padding: 10px 40px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 3%;
  margin-bottom: 3%;
  background-color: white;
  border-color: white;
  color: #444;
  border-radius: 5px;
  float: left; }

.showcase_download a:hover {
  opacity: 0.8; }

.showcase-info {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px; }

.showcase-info-last {
  border-bottom: 1px solid #ccc; }

.showcase-info strong {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900; }

.showcase-info p {
  padding-top: 10px; }

.showcase-info a {
  color: #00b4c9;
  font-weight: 900; }

.showcase-list {
  list-style: none;
  display: flex;
  padding-left: 0; }

.showcase-list-item {
  align-self: center;
  text-align: center; }

.showcase-list-item h3 a {
  color: #ffae00; }

.showcase-list-item img {
  width: 60%;
  margin: auto; }

.slider-description {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 1%; }

.caption h3, .caption span {
  color: white; }

/* @end Showcase */
/*SOCIAL SHARE BUTTONS*/
.social-button-icon {
  font-size: 4em; }
@media (max-width: 750px) {
  .social-button-icon {
    font-size: 2.5em; } }

.social-group {
  position: fixed;
  left: 0;
  z-index: 1; }

.social, .social-round, .social-twitter, .social-linkedin, .social-book, .social-delicious, .social-myspace, .social-stumbleupon, .social-mail, .social-high, .social-digg, .social-wide, .social-facebook, .social-tumblr, .social-blogger, .tags-count-color {
  border-radius: 100%;
  color: white; }

.social-round, .social-twitter, .social-linkedin, .social-book, .social-delicious, .social-myspace, .social-stumbleupon, .social-mail {
  padding: 6px; }

.social-high, .social-digg {
  padding: 7px 5px; }

.social-wide, .social-facebook, .social-tumblr, .social-blogger, .tags-count-color {
  padding: 5px 8px; }

a {
  text-decoration: none; }

.sociaal:hover {
  cursor: pointer; }

.fa-share-alt-square {
  color: #ffae00;
  box-shadow: none !important; }

#sticky-social {
  border: 0 !important;
  box-shadow: none !important;
  margin-left: -10px;
  min-width: 50px;
  background-color: transparent; }

#sticky-social a:hover {
  background: transparent; }

#q-horizontal-social {
  top: 0;
  left: 40px;
  background-color: transparent;
  border: 0 !important;
  box-shadow: none !important;
  min-width: 200px; }

#q-horizontal-social li {
  float: left;
  width: 30px; }

#q-horizontal-social a {
  padding: 0;
  margin: 0; }

#q-horizontal-social a:hover {
  background: transparent; }

.social-facebook {
  background: #3b5998; }

.social-twitter {
  background: #1dcaff; }

.social-linkedin {
  background: #0077b5; }

.social-tumblr {
  background: #35465c; }

.social-digg {
  background: #356AA0; }

.social-blogger {
  background: #f57d00; }

.social-book {
  background: #34a853; }

.social-delicious {
  background: #3399ff; }

.social-myspace {
  background: #000000; }

.social-stumbleupon {
  background: #eb4924; }

.social-reddit {
  border-radius: 100%;
  background: #ff4500;
  color: white;
  padding: 6px; }

.social-mail {
  background: #00b4c9; }

.gooo {
  padding-left: 20px !important;
  padding-top: 5px !important; }

/*SOCIAL SHARE BUTTONS END*/
/*TAGS STYLES*/
.tag-color {
  color: #ffae00; }

.tags-count-color {
  background-color: lightgray; }

/*TAGS STYLES END*/
/*DIFFERENT HOMEPAGE STYLES*/
.blue-big {
  position: relative;
  background-color: #00b4c9; }

.green-big {
  position: relative;
  background-color: #81dfb0; }

.blue-big h3, .green-big h3 {
  color: white;
  margin-top: 0; }

.homepage-card-banner {
  position: relative; }

.homepage-card-banner-text {
  position: absolute;
  margin-right: 50%;
  top: 0; }
@media (max-width: 775px) {
  .homepage-card-banner-text {
    position: relative;
    margin-right: 0; } }

.homepage-card-banner-text h2 {
  margin-top: 12%;
  font-size: 60px;
  line-height: 1.1;
  letter-spacing: -2px;
  font-weight: 500; }
@media (max-width: 905px) {
  .homepage-card-banner-text h2 {
    font-size: 40px; } }

.homepage-card-banner-text p {
  padding-top: 2%;
  font-size: 21px;
  line-height: 1.4; }

.btn-quantum-homepage {
  background: #36DEF3;
  color: #112233;
  border: none;
  margin-top: 2%;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px; }

.btn-quantum-homepage:hover,
.btn-quantum-homepage:active,
.btn-quantum-homepage:focus {
  background: #00b4c9;
  color: #112233; }

.product-container {
  text-align: center;
  padding: 4%; }

.product-container img {
  width: 75%; }

.product-heading {
  color: white;
  margin: 5%;
  font-size: 28px;
  font-weight: bold; }

.product_details {
  color: white;
  font-size: 16px;
  font-weight: lighter;
  height: 150px; }

.see_more a {
  color: white;
  font-size: 28px;
  font-weight: lighter; }

.homepage-whitepaper {
  background: #66D2DF;
  border-radius: 8px;
  padding: 2%;
  margin-top: 3%; }

.whitepaper_part {
  padding-top: 20px;
  padding-bottom: 20px; }

.whitepaper_part h2 {
  margin-top: 0;
  font-size: 26px;
  font-weight: 600; }

.whitepaper_part p {
  font-size: 16px;
  line-height: 1.4;
  color: black; }

.btn-download {
  background: white;
  color: black !important;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 15px;
  margin-top: 15%; }
@media (max-width: 1200px) and (min-width: 980px) {
  .btn-download {
    font-size: 16px;
    padding: 5px 10px; } }

.btn-download:hover {
  background: white !important;
  opacity: 0.9; }

.quote {
  background-color: #00B4C9;
  color: white;
  text-align: center;
  font: "FaktSlabPro-Blond", Arial,Helvetica,sans-serif; }

.quotation {
  font-size: 2em; }

.heading {
  text-align: center; }

.shop-item {
  width: 100%; }

.testimonial-slide-holder {
  margin-top: 80px;
  margin-bottom: 80px; }

.testimonial-quote {
  padding: 5%; }

.testimonial-quote h3 {
  color: black;
  font-style: italic; }

.home-shop-banner {
  margin-left: -15px;
  margin-right: -15px; }

.testimonial-image img {
  border-radius: 50%;
  margin: auto;
  max-width: 90px; }

.testimonial-quote-icon {
  margin-top: -1%;
  color: #DDF1F5; }

.slider-testimonial-img {
  width: 100%;
  border-radius: 50%;
  max-width: 200px;
  margin: auto; }
@media (max-width: 600px) {
  .slider-testimonial-img {
    display: none !important; } }

.slider-testimonial {
  background-color: rgba(0, 180, 0, 0.5);
  padding: 1%; }

.article_wrapper {
  padding: 2%;
  margin: 1%;
  border: 1px solid lightgray; }

/*END DIFFERENT HOMEPAGE STYLES*/
/*OTHER PAGES STYLES*/
.media-left {
  float: left; }

.thumbnail {
  border: none;
  position: relative;
  padding: 0; }

.comments_header {
  padding-top: 5%; }

.expand {
  margin-bottom: 2%; }

.expand a {
  text-transform: uppercase;
  color: #00b4c9;
  font-weight: 700; }

.faq {
  padding: 2%; }

.login_reg {
  padding: 3%; }

.loginn a:hover {
  cursor: pointer; }

.subscribe {
  margin-top: 2%; }

.location_details {
  margin: 1%;
  padding: 1%; }

.location_item {
  padding: 1%;
  margin: 1%;
  border: 1px solid #eeeeee;
  background-color: white; }

.location_item div {
  padding-top: 3%;
  padding-bottom: 3%; }

.highlights li {
  padding: 0;
  padding-right: 20px; }
@media (max-width: 775px) {
  .highlights li {
    width: 100%; } }

.highlights ul {
  list-style: none;
  padding: 0; }

.highlights h3 {
  margin-top: 20px;
  font-size: 28px;
  font-weight: 500;
  color: white; }

.highlights h3 a {
  color: white !important; }

.highlights a, .highlights p {
  color: #333 !important; }

.highlights p {
  color: #333 !important;
  font-size: 15px;
  padding: 0px;
  padding-right: 30px; }

.highlights img {
  min-height: 55px; }

#map {
  height: 1280px; }

.promo {
  color: #00b4c9;
  border: 3px solid #00b4c9;
  padding: 2%; }

.searchbox_q {
  width: 100% !important; }

.sf-FormNav-page {
  margin: 5px; }

.locations-container {
  margin-top: -850px;
  margin-bottom: 24px; }

.locations-container h1 {
  position: relative;
  color: black;
  letter-spacing: -3px;
  margin-bottom: 40px; }

.locations-holder {
  min-height: 600px;
  background: white;
  border-radius: 8px;
  border: none;
  margin-right: 0;
  margin-bottom: 70px; }

.locations-holder img {
  padding: 0;
  border-radius: 8px 8px 0 0; }

.locations-holder-info {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px; }

.locations-holder-info span {
  margin-top: 20px; }

.locations-holder-info h3 {
  color: black;
  font-weight: 700;
  font-size: 28px; }

.locations-holder-info a {
  color: #00b4c9; }

.single-location-container {
  margin-bottom: 20px; }

.single-location-container h1 {
  text-align: center;
  margin-bottom: 20px; }

.request_a_quote {
  background-image: url(/assets/Development_ContactUs.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 5px; }

.write_to_us {
  background-image: url(/assets/images/Writetous.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 5px; }

.form_left_message {
  padding-right: 30%;
  padding-top: 10%; }

.gray_form_container {
  background: #F3F4F6;
  opacity: 0.9;
  border-radius: 8px;
  border: none;
  padding: 5px 20px 20px 20px;
  margin: 70px 0 70px 0; }

.gray_form_container label {
  font-size: 14px;
  font-weight: 400; }

.gray_form_container .form-group {
  margin-bottom: 0; }

.submit-form-button button {
  background: black;
  color: white;
  border: none;
  font-size: 18px;
  width: 100%;
  margin-top: 15px; }

.submit-form-button button:hover {
  background: black;
  color: white;
  opacity: 0.8; }

/*OTHER PAGES STYLES END*/

/*# sourceMappingURL=quantum-style.css.map */
